<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import List from '../../components/partner/tree'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List
  },
  data() {
    return {
      title: "",
    }
  },
  mounted() {
    this.title = this.$t('전체') + '&nbsp;' + this.$t('파트너')
    this.state = this.$route.params.state
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <List/>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
