<script>
import DatePicker from "@vuepic/vue-datepicker"
import BadgeLevel from '@/components/badge-level'
import BadgeState from '@/components/badge-state'
import PartnerTreeMenu from '@/components/partner/tree-menu'
import PopupPartner from './popup'

export default {
  components: {
    DatePicker,
    BadgeLevel,
    BadgeState,
    PartnerTreeMenu,
    PopupPartner
  },
  props: {

  },
  data() {
    return {
      search: {
        groupKey: '',
        searchType: 'username',
        searchText: '',
        beginDate: '',
        endDate: '',
        state: '',
        parent: null
      },
      sort: { 't1.top': -1, 't1.sort': 1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1 // 현재 페이지
    }
  },
  mounted() {
    /*
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 365)
    let endDate = new Date()

    this.search.beginDate = beginDate
    this.search.endDate = endDate
     */

    this.search.parent = null
    this.$refs.partnerTreeMenu.getPartners().then()
    // this.searchList().then()
  },
  methods: {
    refresh: function() {
      document.location.reload()
    },
    setSort: function(field) {
      for(const key in this.sort) {
        if (key !== field) {
          delete this.sort[key]
        }
      }
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort[field] = dir
      this.searchList().then()
    },
    setDefaultSort: function() {
      this.sort = { 't1.top': -1, 't1.sort': 1 }
      this.searchList().then()
    },
    setParent: function(id) {
      this.search.parent = id
    },
    searchList: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const params = {
          search: {parent: this.search.parent},
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText
        params.search.state = this.state

        const result = await this.$API.partner.list(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        this.list = result.documents

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
        this.page = result.page
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
}
</script>

<template>
  <div ref="contentWrapper">
    <form class="header-search" @submit.prevent="searchList">
      <div class="row">
        <div class="form-group col-md-1 mb-2">
        </div>
        <div class="form-group col-md-3 mb-2">
          <DatePicker
              v-model="search.beginDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('시작일')"
          ></DatePicker>
        </div>

        <div class="form-group col-md-3 mb-2">
          <DatePicker
              v-model="search.endDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('종료일')"
          ></datepicker>
        </div>

        <div class="form-group col-md-2 mb-2">
          <select class="form-select" v-model="search.searchType">
            <option value="username">{{ $t('아이디') }}</option>
            <option value="siteUsername">{{ $t('사이트 아이디') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" />
        </div>

        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-dark w-100">검색</button>
        </div>
      </div>

    </form>

    <hr class="mt-0 mb-3" />

    <div class="w-100">
      <div class="d-md-flex">

        <!-- 상위 목록 //-->
        <partner-tree-menu :partner="$store.getters['auth/user']" @setParent="setParent" @searchList="searchList" ref="partnerTreeMenu"></partner-tree-menu>

        <!-- 목록 //-->
        <div ref="contentList" class="table-responsive mb-0 rounded-3 w-100" style="min-height: 800px;">
          <div class="w-100">
            <div class="card">
              <div class="card-body">

                  <table class="table table-nowrap table-centered align-middle table-striped sticky-table">
                    <thead class="table-dark">
                    <tr>
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setDefaultSort()">비고</a>&nbsp;
                      </th>
                      <th scope="col">{{ $t('상위') }}</th>
                      <th scope="col">{{ $t('등급') }}</th>
                      <th scope="col" style="min-width: 300px;">{{ $t('아이디') }}</th>
                      <th scope="col">식별자</th>
                      <th scope="col">{{ $t('수수료') }}</th>
                      <th scope="col">{{ $t('상태') }}</th>
                      <th scope="col" :hidden="$store.getters['auth/user'].type !== 'admin'">{{ $t('에볼루션') }}</th>
                      <th scope="col" :hidden="$store.getters['auth/user'].type !== 'admin'">{{ $t('프라그마틱') }}</th>
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setSort( 't1.cash')">{{ $t('보유캐쉬') }}</a>&nbsp;
                        <template v-if="sort['t1.cash']"><span class="fa fa-sort-amount-down" v-if="sort['t1.cash'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t1.cash'] === 1"></span></template>
                      </th>
                      <th scope="col">{{ $t('하부보유캐쉬') }}</th>
                      <!--
                      <th scope="col">{{ $t('회원캐쉬') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="회원들의 총 보유캐쉬"></span></th>
                      <th scope="col">{{ $t('전체캐쉬') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="본인 포함한 하부 총 보유캐쉬"></span></th>
                      <th scope="col">{{ $t('지급/회수') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="본인이 상부로부터 지급 받거나 회수된 캐쉬"></span></th>
                      <th scope="col">{{ $t('하위') }} {{ $t('지급/회수') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="본인이 하부에게 지급하거나 회수한 캐쉬"></span></th>
                      -->
                      <!--
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setSort('t2.bet')">배팅</a>
                        <template v-if="sort['t2.bet']"><span class="fa fa-sort-amount-down" v-if="sort['t2.bet'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.bet'] === 1"></span></template>
                      </th>
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setSort('t2.draw')">무</a>
                        <template v-if="sort['t2.draw']"><span class="fa fa-sort-amount-down" v-if="sort['t2.draw'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.draw'] === 1"></span></template>
                      </th>
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setSort('t2.win')">승</a>
                        <template v-if="sort['t2.win']"><span class="fa fa-sort-amount-down" v-if="sort['t2.win'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.win'] === 1"></span></template>
                      </th>
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setSort('t2.cancel')">취소</a>
                        <template v-if="sort['t2.cancel']"><span class="fa fa-sort-amount-down" v-if="sort['t2.cancel'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.cancel'] === 1"></span></template>
                      </th>
                      <th scope="col" @click="setSort('t2.valid')">
                        <a href="javascript:void(0)" @click="setSort('t2.valid')">유효배팅</a>
                        <template v-if="sort['t2.valid']"><span class="fa fa-sort-amount-down" v-if="sort['t2.valid'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.valid'] === 1"></span></template>
                      </th>
                      <th scope="col" @click="setSort('t2.winlose')">
                        <a href="javascript:void(0)" @click="setSort('t2.winlose')">수익금</a>
                        <template v-if="sort['t2.winlose']"><span class="fa fa-sort-amount-down" v-if="sort['t2.winlose'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.winlose'] === 1"></span></template>
                      </th>
                      //-->
                      <!--
                      <th scope="col">{{ $t('하부') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="보유 총 파트너수"></span></th>
                      <th scope="col">{{ $t('게임사') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="설정된 게임사수"></span></th>
                      -->
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setSort('t1.updatedAt')">{{ $t('수정일') }}</a>&nbsp;
                        <template v-if="sort['t1.updatedAt']"><span class="fa fa-sort-amount-down" v-if="sort['t1.updatedAt'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t1.updatedAt'] === 1"></span></template>
                      </th>
                      <th scope="col">
                        <a href="javascript:void(0)" @click="setSort('t1.createdAt')">{{ $t('시간') }}</a>&nbsp;
                        <template v-if="sort['t1.createdAt']"><span class="fa fa-sort-amount-down" v-if="sort['t1.createdAt'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t1.createdAt'] === 1"></span></template>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="record in list" :key="record.id">
                      <template v-if="record.id !== $store.getters['auth/user'].id">
                        <!-- 메뉴 //-->
                        <td>
                          <popup-partner :partner="record" @sl="searchList" @rf="refresh"></popup-partner>
                        </td>
                        <td class="text-start">






                          <router-link :to="'/partner/detail/' + record.top.id" target="_blank" v-if="record.top && record.top.id !== record.id &&  ($store.getters['auth/user'].level != 'super' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.top" :show-name="true"></badge-level></router-link>
                          <router-link :to="'/partner/detail/' + record.super.id" target="_blank" v-if="record.super && record.super.id !== record.id && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.super" :show-name="true"></badge-level></router-link>
                          <router-link :to="'/partner/detail/' + record.master.id" target="_blank" v-if="record.master && record.master.id !== record.id && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.master" :show-name="true"></badge-level></router-link>
                          <router-link :to="'/partner/detail/' + record.agent.id" target="_blank" v-if="record.agent && record.agent.id !== record.id && ($store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.agent" :show-name="true"></badge-level></router-link>

                          <!--
                          <badge-level :show-name="true" :partner="record.top" v-if="record.top && record.top.id !== record.id &&  ($store.getters['auth/user'].level != 'top' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></badge-level>
                          <badge-level :show-name="true" :partner="record.super" v-if="record.super && record.super.id !== record.id && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></badge-level>
                          <badge-level :show-name="true" :partner="record.master" v-if="record.master && record.master.id !== record.id && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></badge-level>
                          <badge-level :show-name="true" :partner="record.agent" v-if="record.agent && record.agent.id !== record.id && ($store.getters['auth/user'].level != 'shop')"></badge-level>
                          -->

                        </td>
                        <td class="text-start">
                          <div class="row">
                            <badge-level :show-name="false" :partner="record"></badge-level>
                          </div>
                        </td>
                        <!-- 정보 //-->
                        <td class="text-start">
                          <div class="row">
                            <div :class="'bg-soft col-' + record.depth" v-if="record.depth > 0"></div>
                            <div :class="'text-start col-' + (12-record.depth)">
                              <span v-if="record.level !== 'top'">ㄴ&nbsp;</span>
                              <span class="ms-1"><router-link :to="'/partner/detail/' + record.id" target="_blank">{{ record.username }}<small> [{{ record.nickname }}]</small></router-link></span>
                              <!--<span>{{ record.username }}<small> ({{ record.nickname }})</small></span>-->
                            </div>
                          </div>
                            <div class="row" v-if="record.memo">
                                <div class="col-md-12 border-top mt-2 pt-1 small text-secondary">
                                    {{ record.memo }}
                                </div>
                            </div>
                        </td>
                        <!-- prefix //-->
                        <td>{{ record.prefix }}</td>
                        <!-- 알 지급율 //-->
                        <td>
                          {{ record.cashRate }}%
                        </td>
                        <!-- 상태 //-->
                        <td>
                          <badge-state :user="record"></badge-state>
                        </td>
                        <!-- 에볼루션 스위치 //-->
                        <td :hidden="$store.getters['auth/user'].type !== 'admin'">
                          <span class="badge bg-success p-1" v-if="record.useEvo == 1">활성</span>
                          <span class="badge bg-danger p-1" v-else>비활성</span>
                        </td>
                        <!-- 프라그마틱 스위치 //-->
                        <td :hidden="$store.getters['auth/user'].type !== 'admin'">
                            <span class="badge bg-success p-1" v-if="record.usePrag == 1">활성</span>
                            <span class="badge bg-danger p-1" v-else>비활성</span>
                        </td>
                        <!-- 보유캐쉬 //-->
                        <td class="text-end">
                          <!-- div><span class="badge bg-success">어제</span> {{ record.yesterdayCash.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.cash.floatVal(2).formatThousands() }}</div> //-->
                          {{ record.cash.floatVal(2).formatThousands() }}
                        </td>
                        <!-- 하부캐쉬 //-->
                        <td class="text-end">
                          <!-- <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.partnerCash.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.partnerCash.floatVal(2).formatThousands() }}</div> //-->
                          {{ record.partnerCash.floatVal(2).formatThousands() }}
                        </td>
                        <!--
                        <td class="text-end">
                          <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.bet.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.bet.floatVal(2).formatThousands() }}</div>
                        </td>

                        <td class="text-end">
                          <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.draw.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.draw.floatVal(2).formatThousands() }}</div>
                        </td>

                        <td class="text-end">
                          <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.win.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.win.floatVal(2).formatThousands() }}</div>
                        </td>

                        <td class="text-end">
                          <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.cancel.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.cancel.floatVal(2).formatThousands() }}</div>
                        </td>

                        <td class="text-end">
                          <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.valid.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.valid.floatVal(2).formatThousands() }}</div>
                        </td>

                        <td class="text-end">
                          <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.winlose.floatVal(2).formatThousands() }}</div>
                          <div><span class="badge bg-primary">금일</span> {{ record.winlose.floatVal(2).formatThousands() }}</div>
                        </td>
                        //-->
                        <!-- 하부 갯수 //-->

                        <!-- 업데이트 일자 //-->
                        <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.updatedAt">{{ new Date(record.updatedAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</span></td>
                        <!-- 생성일자 //-->
                        <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.createdAt">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</span></td>
                      </template>
                    </tr>
                    </tbody>
                  </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
