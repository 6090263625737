<script>

export default {
  components: {

  },
  data() {
    return {
      openDialog: false,
      params: {
        userId: '',
        amount: 0,
        memo: ''
      },
      amount: 0,
      user: null,
      process: false
    };
  },
  mounted() {
    this.amount = this.$store.getters['auth/user'].cash.floatVal()
  },
  methods: {
    open: function(user) {
      this.openDialog = true
      this.params.userId = user.id
      this.params.memo = ''
      this.params.amount = 0
      this.user = user
    },
    add: function(amount) {
      if (amount === -1) {
        this.params.amount = 0
      } else {
        this.params.amount += amount
      }
    },
    request: async function() {
      if (this.process === true) return
      try {
        this.process = true
        if (this.params.amount <= 0) {
          return alert(this.$t('text.error_amount_is_nov_valid'))
        }

        if (!this.params.userId) {
          return alert(this.$t('text.error_user_id_is_nov_valid'))
        }

        const result = await this.$API.user.deposit(this.params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        alert(this.$t('text.work_completed'))
        this.openDialog = false
        // this.user.cash = result.cash
        this.$store.commit('auth/SET_CASH', result.senderCash)
        this.$emit('refresh')
      } finally {
        this.process = false
      }
    }
  }
};
</script>

<template>
  <div>
    <b-modal
        :title="$t('충전')"
        v-model="openDialog"
        scrollable
        centered
        button-size="sm"
        hide-footer
    >
      <div class="row">
        <div class="col-md-12">
          <form @submit.prevent="request">
            <div class="form-floating mb-3">
              <VueNumberFormat class="form-control text-end" :placeholder="$t('보유금액')" v-model:value="amount" :options="{ precision: 0, prefix: '', suffix: '', decimal: '.', thousand: ',', acceptNegative: false, isInteger: true  }" disabled></VueNumberFormat>
              <label class="form-label">{{ $t('보유금액') }}</label>
            </div>

            <div class="form-group mb-1 text-center">
              <div class="btn-group">
                <button type="button" class="btn btn-info btn-sm" @click="add(100000)">100,000</button>
                <button type="button" class="btn btn-warning btn-sm" @click="add(500000)">500,000</button>
                <button type="button" class="btn btn-danger btn-sm" @click="add(1000000)">1,000,000</button>
                <button type="button" class="btn btn-pink btn-sm" @click="add(10000000)">10,000,000</button>
                <button type="button" class="btn btn-dark btn-sm" @click="add(100000000)">100,000,000</button>
                <button type="button" class="btn btn-secondary btn-sm" @click="add(-1)">취소</button>
              </div>
            </div>

            <div class="form-floating mb-3">
              <VueNumberFormat class="form-control text-end" :placeholder="$t('충전액')" v-model:value="params.amount" :options="{ precision: 0, prefix: '', suffix: '', decimal: '.', thousand: ',', acceptNegative: false, isInteger: true  }"></VueNumberFormat>
              <label class="form-label">{{ $t('충전액') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('메모')" v-model="params.memo" />
              <label class="form-label">{{ $t('메모') }}</label>
            </div>

            <div class="text-end">
              <hr />
              <button type='submit' class='btn btn-primary me-1' :disabled="process === true">{{ $t('신청') }}</button>
              <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>
